// main: App.scss





  .category-card__sub-title{
      z-index:100;
      position: relative;
  }

  h4.section__title{

  text-transform: uppercase;
  margin-top: 63px;
  padding-top: 4px;
  border-top: 1px solid $beige-light;

}

.primary-categories__card-grid{


    .category-card {
       padding: 15px;

       @include breakpoint($mob_400){
         padding: 8px 15px 8px 15px;
       }



       .category-card__content-wrapper{
          position: relative;
          height: 384px;
         	background-color: $light-beige;
          padding: 18px 15px 18px 15px;
          border-radius: 5px;
          @include transition(background-color 0.4s);


          @include breakpoint($mob_480){
            height: 340px;
          }

          @include breakpoint($mob_400){
            height: 220px;
          }

          .category-data{
            position: absolute;
            top: 158px;

            @include breakpoint($mob_400){

              top: 91px;

            }

            .category-data__load-capacity{
              margin-top: 23px;

              @include breakpoint($mob_400){

                margin-top: 15px;

              }
            }
          }

          h4.category-data__tag{

            text-transform: uppercase;

          }

          h6.category-data__tip{

            letter-spacing: 0.01em;
            text-transform: none;
            width: 130px;
            margin-top: 4px;

          }

          img.category-card__image{
            position: absolute;
            right: 15px;
            bottom: 0px;
            height: 73%;

            @include breakpoint($tab_960){

            // max-width: 190px;
            }

            @include breakpoint($tab_767){

            // max-width: 232px;



            }

            @include breakpoint($mob_480){
              height: 65%;
              // max-width: 220px;

            }

            @include breakpoint($mob_400){
              height: 65%;
              // max-width: 142px;

            }
          }
       }

       //hover settings
       &:hover{

         .category-card__content-wrapper{
            background-color: $light-beige-2;
         }

         h2.black.medium{

           text-decoration: none !important;
         }

       }

    }

    a:hover{
      text-decoration: none;
    }



}
