// main: App.scss

.share-popup-container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
  z-index: 100000;
  top: 0;
  left:0;

  .share-popup-wrapper{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 700px;
    height: 300px;

    @media screen and (max-width: 767px){
      width: 100%;
      height: 50%;
      padding: 0 20px;
    }
  }

  .share-popup{
    width: 100%;
    height: 100%;
    padding: 40px 20px 20px 20px;
    background-color: $white;
    border-radius: 1rem;
    position: relative;

    .share-popup__text-group{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .share-popup__actions-group{
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 2rem
    }

    #share_popup__link_text{
      border: 2px solid $light-beige;
      padding: 10px 10px;
      height: 36px;
      font-size: 20px;
    }

    .share-popup__copy-link-btn{
      border: 2px solid $light-beige;
      border-left: 0
    }

    .share-popup__whatsapp-share-btn{
      background-color: $light-green;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      padding-right: 2rem;

      > svg{
        height: 100%;
        margin-right: 1rem;
      }


      &:hover{
        background-color: $green;
      }
    }
  }
}

.config-castor-wrapper{
  margin-top: 124px;

  h4.section__title{
    margin-top: 0;
  }
}

h3.config-castor__title{
margin-top: 144px;
}

div.config-panel{
  margin-top: 18px;
  border: 1px solid $beige;
  // height: 648px;
  border-radius: 5px;
}

div.config-panel .series-bar{
  background-color: $light-beige;
  height: 36px;

  h4{
    padding: 6px 0 0 15px;
    text-transform: capitalize;

  }
}

.config-panel__body{
  // display: flex;
}

.config-panel__body .image-wrapper{
  display: flex;
  position: relative;
  height: 546px;
  // width: 60%;

  img{
    display: block;
    margin: auto;
    height: 80%
    // width: 50%;


  }
}

@media screen and (max-width: 991px){
  .config-panel__body .image-wrapper{

    img{
      height: 60%;
    }
  }
}

@media screen and (max-width: 767px){
  .config-panel__body .image-wrapper{

    height: 220px;
    img{
      height: 90%;
    }
  }
}

.config-fields{
  position: relative;
  padding-top: 15px ;
  // height: 546px;
  // overflow: auto
  // width: 40%;
}

.config-field-dd-material, .config-field-dd-wheel-dia,
.config-fields-vitalsMeta-bracket-quantity{
  // position: absolute;
  // right: 15px;
  width: 100%;
  // padding-left: 30px;
}

.config-field-dd-material{
  z-index: 1000;
}

.config-field-dd-wheel-dia{
  top: 169px;
  z-index: 999;
}

.config-fields-vitalsMeta-bracket-quantity{
  top: 257px;

  &>div{
      margin: 0 15px 15px 15px;

      &:last-of-type{
        margin-bottom: 0px;
      }
  }
}

//only product details pages

.only-details-pg{

  .config-fields-vitalsMeta-bracket-quantity{
    top: 257px;

    &>div{
        margin-bottom: 40px;
    }
  }

  .product-name{
    padding-bottom: 5px;
    border-bottom: 1px solid $beige-light;
  }

  .product-descp{
    padding-top:  9px;
  }

  table.product-specs{
    width: 100%;

    tr.table-header{
      border-bottom: 0.5px solid $beige;

    }

    tr.table-row {
      border-bottom: 1px solid $beige-light;
    }

    th{
      padding-bottom: 5px;
    }

    td{
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.configure-panel__cta{

  .primary_cta_wrapper, .secondary_cta_wrapper{
    display: flex;
    padding: 15px 30px;


  }

  .secondary_cta_wrapper{
    @media screen and ( max-width: 767px){
      padding: 30px;
      padding-bottom: 15px;
    }
  }

  .primary_cta_wrapper{
    @media screen and ( max-width: 767px){
      padding: 0 30px;
      padding-bottom: 15px;
    }

    .cta_cont{
      width: 50%;



      &:last-of-type{
        padding-right: 0px;
      }

      button{
        width: 100%;

      }
    }
  }

  .cta_cont{
    padding-right: 15px;

    &:last-of-type{
      padding-right: 0px;
    }
  }
}


.image-wrapper .thumbnailSet{
  position: absolute;
  margin: 15px;

  .thumbnail{
    width: 80px;
    height: 80px;
    margin-bottom: 10px;

    &:hover{
      border: 1px solid $beige;
    }

    &.active{
      border: 2px solid $beige;
    }

    img{
      height: 100%;
    }
  }
}
