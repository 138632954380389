// main: App.scss

.dd-wrapper{
  @include no-select();

  background-color: white;

  &.dd-material{
    right: 50px;
    z-index: 1000;
    margin: 0 15px 15px 15px;
  }

  &.dd-wheel-dia{
    right: 50px;
    top: 300px;
    z-index: 999;

    margin: 0 15px 10px 15px;
  }
}

.radio-label, .dd-label, .input-label{
  margin-bottom: 5px;
}

.dd-select{
  border: 0.5px solid $beige;
  width: 100%;
  position: relative;

  &:hover{
    cursor: pointer;
  }
}

.dd-flex-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dd-activeOption{
  padding: 10px 10px 10px 10px;

  &:hover{
      background-color: $light-beige;
  }
}

.dd-activeOption.dd-material{
  height: 104px;
  border-bottom: 0.5px solid $beige;
}

.dd-activeOption.dd-wheel-dia{
  height: 50px;
  border-bottom: 0.5px solid $beige;
}


.dd-list{
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
  // width: 435px;
  position: absolute;
  left: 0;

  background-color: $white;
  outline: 0.5px solid $beige;
  // margin-right: 30px;
  z-index: 100;
  @include drop-shadow-3;

  &.dd-material{
    max-height: 345px;
    overflow-y: scroll;
    top: 103px;
    width: 100%;
  }

  &.dd-wheel-dia{
    max-height: 345px;
    overflow-y: scroll;
    width: 100%;
    top:49px;
  }
}

.dd-list-item{
  padding: 10px 10px 10px 10px;

  &:last-of-type{
    border-bottom: 0px;
  }

  &:hover{
    background-color: $light-beige;
  }
}

.dd-list-item.dd-material{
  height: 104px;
}

.dd-list-item.dd-wheel-dia{
  height: 50px;
}



.dd-list-item.active{
background-color: $light-beige-2;
}

.radio-list{
  list-style-type: none;
  padding-left: 0px;
  // width: 435px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  // margin-right: 15px;
}

.radio-list-item{

  width: 50%;



  >div{
    border: 0.5px solid $beige;
    height: 50px;
    padding-left: 10px;
    padding-top: 6px;
    margin-bottom: 12px;

    &:hover{
      background-color: $light-beige;
      cursor: pointer;
    }

  }


  &:nth-child(2n+1)>div{
    margin-right: 6px;
  }

  &:nth-child(2n)>div{
    margin-left: 6px;
  }



  &.active{
    &>div{
        background-color: $light-beige-2;
    }

  }
}


/* Create a custom checkbox */
.checkbox {

  opacity: 0; // hide it
  height: 0;
  margin: 0 !important;


  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    margin-top: -1px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #B5A98C;
  }

  // Box hover
  &:hover + label:before {
    background: $light-beige-2;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: $beige;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 11px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}

//disabled checkbox styling

.checkbox-block.disabled label{
  color: $light-grey;
  cursor: default;
}

.checkbox-block.disabled label:before{
  display: none !important;

}

.checkbox-block.disabled label:after{
  display: none !important;

}

//wheel-config-meta

.wheel-config-meta{
  display: flex;

  div{
    margin-right: 32px;

    &:last-of-type{
      margin-right: 0px;
    }
  }
}

.wheel-config-meta h5.beige.antique{
  line-height: 15px;
}

.wheel-config-meta h4{
  margin-top: 6px;
}

.input-block{
  // margin-right: 15px;
}

input{
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  border-style: none;
  border: 0.5px solid $beige ;
  outline: none;
  height: 50px;
  font-family: 'suisse-light';
  font-size: 32px;
  line-height: 36px;
  padding-left: 10px;
  width: 100%;
  color: $black;
}

textarea{
  font-family: 'suisse-light';
  font-size: 32px;
  line-height: 36px;
  padding-left: 10px;
  padding-top: 5px;
  border: 0.5 solid $beige;

}
