// main: App.scss


.category-page-content{
    margin-top: 124px;

  .series-block{
    position: relative;

    .overview{
      background-color: rgba(181,169,140,0.1);


      .container{

        h3.overview__title{


          margin-top: 16px;
          // margin-bottom: 16px;
        }

        .overview__data__block{

          display: flex;
          padding-bottom: 15px;

          .overview__data{
              margin: 23px 36px 0 0;
              &:last-of-type{
                margin-right: 0px;
              }
          }


          h4.data__tag{

            letter-spacing: 0.01em;
            text-transform: uppercase;

            @include breakpoint($tab_640){
              width: 60px;
            }

          }

          h4.data__qty{


            .beige{
              color: $beige;
            }
          }
        }
      }
    }

    .container.product-cards{
      padding: 0px;

    .product-cards__card-grid{
      margin: 0 0 45px 0;

      a{
        text-decoration: none;
      }

    .product-card{
      padding: 15px;
    //  min-width: 360px;

      .product-card__content-wrapper{

        position: relative;
        border: 0.5px solid $beige;
        border-radius: 5px;
        height: 366px;
        padding: 22px 15px 15px 15px;
        @include transition(background-color 0.4s);


        @include breakpoint($mob_400){
          height: 280px;
        }

        &:hover{
          background-color: $light-beige;

          button{
            background-color: $light-beige-2;
          }

          h2.product-card__title>span{
            background-color: rgba(0,0,0,0);
          }

          h4.product-card__sub-title>span{
            background-color: rgba(0,0,0,0);
          }

        }

        h2.product-card__title{

          width: 70%;
          border-bottom: 0.5px solid $light-beige-2;
          padding-bottom: 5px;
          position: relative;
          z-index: 100;

          span{
            background-color: $white-trans;
            @include transition(background-color 0.4s);
          }
        }


        h4.product-card__sub-title{

          width: 50%;
          margin-top: 9px;
          position: relative;
          z-index: 100;
          display: -webkit-box !important;
          /* autoprefixer: off */
          -webkit-box-orient: vertical !important;
          /* autoprefixer: on */
          -webkit-line-clamp: 3 !important;
          overflow: hidden !important;

          span{
            background-color: $white-trans;
            @include transition(background-color 0.4s);
          }
        }

        .product-data{

          position: absolute;
          bottom: 63px;

          .product-data__load-capacity{
            margin-top:10px;
          }

          h5{
            text-transform: uppercase;
            letter-spacing: 0.03em;
          }

          h4.product-data__qty{

            // margin-top: 5px;

          }
        }

        img.product-card__image{
          position: absolute;
          right: 5px;
          top: 30px;
          height: 63%;
          z-index: 10;
          // max-width: 180px;

          // @include breakpoint($mob_400){
          //   max-width: 142px;
          // }

          // &.pt1{
          //   height: 50%;
          //   top: 60px;
          // }
          // &.pt2{
          //   height: 50%;
          //   top: 60px;
          // }
          // &.pt3{
          //   height: 50%;
          //   top: 60px;
          // }
          // &.pt4{
          //   height: 50%;
          //   top: 60px;
          // }
        }




          button{
            display: block;

            width: calc(100% - 30px);
            position: absolute;
            bottom: 15px;

            h4{
              line-height: normal;
            }

        }


      }
    }
  }
}
  }

  .series-block-anchor{
    position: absolute;
    top: -124px;
  }
}
