// main: App.scss

.contact-page-container{
  margin-top: 124px;

  .section__title.contact-page{
    margin-top: 4px;
  }

  .back-to-cart-btn {

    img{
      margin-top: -2px;
    }

    h4{
    display: inline-block;
    padding-left: 5px;
  }

  }



  label{
    width: 100%;

    @include breakpoint($tab_767){
      width: 100%;
    }
  }

  form[name=contact] {
    padding-top: 20px;

    input{
      margin-top: 10px;
    }

    textarea[name=message]{
      display: block;
      width:100%;
      min-height: 150px;
      color: $black;
    }
  }

.hidden-field{
  visibility: hidden;
  height: 0;
}

}
