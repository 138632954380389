// main: App.scss

//banner styling

.banner{
  display: block;
  position: relative;
  margin-top: 74px;
  width: 100%;
  height: 288px;
  z-index: 9999;
  overflow: hidden;
  background-image: url('../../images/banner-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 232px;

  @include breakpoint($tab_991){
    margin-top: 48px;
  }

  .container{
    height: 100%;
    display: flex;
    align-items: center;

  }

  @include breakpoint($mob_480){
    height: 229px;

  }

  h1.banner__caption{

    // margin-top: 48px;
    text-align: left;
    max-width: 798px;
    color: $black;

    @include breakpoint($tab_960){

    }

    @include breakpoint($tab_800){

      font-size: 42px;
      line-height: 1.3em;
    }

    @include breakpoint($tab_640){

      font-size: 40px;
      line-height: 48px;
    }

    @include breakpoint($mob_480){

      font-size: 32px;
      line-height: 40px;

    }

    @include breakpoint($mob_400){

      font-size: 28px;
      line-height: 36px;

    }

    //learn more span
    span{
      margin-left: 13px;
      font-family: 'suisse-regular';
      font-size: 14px;
      line-height: 18px;
      white-space: nowrap;

      @include breakpoint($mob_400){
        // display: block;
        // margin: 0;
        // padding-top: 2px;
      }

      a{
        color: $black;
        @include transition(background-color 0.4s);
        &:hover{
          background-color: $orange-extra-trans;
          text-decoration: none;
        }
      }

      img{
        margin: 0 0 2px 4px;
      }
    }

  }
}
