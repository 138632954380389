// main: App.scss

h3.best-sellers__title{
  margin-top: 63px;
}

.best-sellers__card-grid{

  .best-sellers-card{
      padding: 15px;

      @include breakpoint($tab_1080){

      }

      @include breakpoint($tab_767){

      }

      .best-sellers-card-plus-bar{
          height: 382px;
        	border: 0.5px solid $beige;
          border-radius: 5px;
          cursor: pointer;

          &:hover{

            .best-sellers-card__content-wrapper{
              background-color: $light-beige;

              button{
                background-color: $light-beige-2;
              }
            }

          }

          @include breakpoint($mob_400){
            height: 316px;
          }

          .best-sellers-card__category-bar{
            position: relative;
            background-color: $light-beige;
            height: 36px;

            @include breakpoint($mob_400){
              height: 32px;
            }

            h4.best-sellers-card__category-name{
              position: absolute;
              left: 15px;
              top: 50%;
              @include translateY(-50%);
            }
          }

          .best-sellers-card__content-wrapper{

            padding: 22px 15px 15px 15px;
            position: relative;
            height: 346px;
            @include transition(background-color 0.4s);

            @include breakpoint($tab_767){
              padding: 16px 15px 15px 15px;
            }

            @include breakpoint($mob_400){
              height: 280px;
            }

            h2.best-sellers-card__title{
              width: 80%;
              border-bottom: 0.5px solid $light-beige-2;
              padding-bottom: 5px;
            }

            h4.best-sellers-card__sub-title{
              width: 50%;
              margin-top: 9px;
            }

            img{
              position: absolute;
              right: 5px;
              top: 15px;
              height: 63%;
              // max-width: 144px;

              // @include breakpoint($tab_767){
              //   max-width: 180px;
              // }
              //
              // @include breakpoint($mob_400){
              //   max-width: 142px;
              // }
            }

            .product-data{
              position: absolute;
              top: 188px;

              @include breakpoint($mob_400){
                top: 131px;
              }

              .product-data__load-capacity:nth-child(2){
                margin-top: 10px;

                @include breakpoint($mob_400){
                  margin-top: 10px;
                }
              }

              h5.product-data__tag{

                text-transform: uppercase;
                letter-spacing: 0.03em;

              }

              h4.product-data__qty{

                // margin-top: 5px;

              }
            }

            button{
              display: block;
              width: calc(100% - 30px);
              position: absolute;
              bottom: 15px;

              h4{
                line-height: normal;
              }

          }

        }
    }
  }
}
