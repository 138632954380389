// main: App.scss

//text styles
h1, h2, h2.proj-title, h3, h4, h5, h6, p, p strong, ul, li, figcaption, a, label, th, tr, td{
  @include text-render-fix();

  letter-spacing: inherit;
  font-weight: normal;

}

a:hover{
  text-decoration: none;
}


.skeletonBlock{
  z-index: 10;

  background: linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%);
  position: absolute;

  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% { background-position: 0% 0%; }
    100% { background-position: -135% 0%; }
  }

  @-webkit-keyframes pulse {
    0% { background-position: 0% 0%; }
    100% { background-position: -135% 0%; }
  }

  &.cat_tile{
    right: 15px;
    bottom: 15px;
    width: 40%;
    height: 73%;
  }

  &.prod_card{
    right: 5px;
    top: 30px;
    width: 40%;
    height: 63%;
  }
}





//h1
h1.black.light{
  font-family: 'suisse-light';
  font-size: 50px;
  line-height: 64px;
  color: $black;

}

h1.black.medium{
  font-family: 'suisse-medium';
  font-size: 50px;
  line-height: 64px;
  color: $black;

}

h1.black.regular{
  font-family: 'suisse-regular';
  font-size: 50px;
  line-height: 64px;
  color: $black;

}

//h2
h2.black.light{

  font-family: 'suisse-light';
  font-size: 32px;
  line-height: 36px;
  color: $black;

  @include breakpoint($mob_400){

    font-size: 20px;
    line-height: 22px;

  }
}

h2.beige.light{

  font-family: 'suisse-light';
  font-size: 32px;
  line-height: 36px;
  color: $beige;

  @include breakpoint($mob_400){

    font-size: 20px;
    line-height: 22px;

  }
}

h2.black.medium{

  font-family: 'suisse-medium';
  font-size: 32px;
  line-height: 36px;
  color: $black;

  @include breakpoint($mob_400){

    font-size: 20px;
    line-height: 22px;

  }
}

h2.black.antique{

  font-family: 'suisse-antique';
  font-size: 32px;
  line-height: 36px;
  color: $black;

  @include breakpoint($mob_400){

    font-size: 20px;
    line-height: 22px;

  }
}

//h3
h3.black.medium{

  font-family: 'suisse-medium';
  font-size: 25px;
  color: $black;
  line-height: 30px;

}

//h4
h4.plus.black.antique{

  font-family: 'suisse-antique';
  font-size: 24px;
  line-height: 36px;
  color: $black;

  @include breakpoint($mob_400){

    font-size: 18px;
    line-height: 24px;

  }
}

h4.beige.regular {
  font-family: 'suisse-regular';
  font-size: 16px;
  line-height: 24px;
  color: $beige;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

h4.black.regular{
  font-family: 'suisse-regular';
  font-size: 16px;
  line-height: 24px;
  color: $black;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

h4.orange.regular{
  font-family: 'suisse-regular';
  font-size: 16px;
  line-height: 24px;
  color: $orange;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

h4.beige.medium{
  font-family: 'suisse-medium';
  font-size: 16px;
  line-height: 24px;
  color: $beige;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

h4.black.medium {
  font-family: 'suisse-medium';
  font-size: 16px;
  line-height: 24px;
  color: $black;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

h4.grey.medium {
  font-family: 'suisse-medium';
  font-size: 16px;
  line-height: 24px;
  color: $grey;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

h4.black.antique {
  font-family: 'suisse-antique';
  font-size: 16px;
  line-height: 24px;
  color: $black;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

h4.beige.antique {
  font-family: 'suisse-antique';
  font-size: 16px;
  line-height: 24px;
  color: $beige;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

h4.orange.antique {
  font-family: 'suisse-antique';
  font-size: 16px;
  line-height: 24px;
  color: $orange;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

span.regular{
  font-family: 'suisse-regular';
}

//h5
h5.beige.medium{
  font-family: 'suisse-medium';
  font-size: 14px;
  line-height: 1.4em;
  color: $beige;
}

h5.beige.antique{
  font-family: 'suisse-antique';
  font-size: 14px;
  line-height: 1.4em;
  color: $beige;
}

//h6
h6.light.orange{
  font-family: 'suisse-light';
  font-size: 12px;
  line-height: 16px;
  color: $orange;

  // @include breakpoint($mob_400){
  //
  //   font-size: 10px;
  //   line-height: 12px;
  //
  // }
}

.h6.medium.beige{
  font-family: 'suisse-medium';
  font-size: 12px;
  line-height: 16px;
  color: $beige;
}

//label
label{
  font-weight: 100;
}

label.beige.antique{
  font-family: 'suisse-antique';
  font-size: 16px;
  line-height: 24px;
  color: $beige;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

label.black.medium{
  font-family: 'suisse-medium';
  font-size: 16px;
  line-height: 24px;
  color: $black;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

tr.beige.medium{
  font-family: 'suisse-medium';
  font-size: 16px;
  line-height: 24px;
  color: $beige;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

tr.black.regular{
  font-family: 'suisse-regular';
  font-size: 16px;
  line-height: 24px;
  color: $black;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}


tr.black.light{

  font-family: 'suisse-light';
  font-size: 32px;
  line-height: 36px;
  color: $black;

  @include breakpoint($mob_400){

    font-size: 20px;
    line-height: 22px;

  }
}

tr.black.antique {
  font-family: 'suisse-antique';
  font-size: 16px;
  line-height: 24px;
  color: $black;

  @include breakpoint($mob_400){

    font-size: 14px;
    line-height: 1.4em;

  }
}

//button styles
button:focus{
  outline: none;

}

button{
  height: 36px;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;

  h4.black.antique{ //this is overriidinng the general fonnt resize behaviour of h4
    @include breakpoint($mob_400){
      font-size: 16px;
    }
  }

  &.primary{
    border: none;
    background-color: $orange-trans;
    @include transition(background-color 0.4s);

    &:hover{
      background-color: $orange;
    }
  }

  &.secondary{
    text-transform: uppercase;
    border: 2px solid $black;
    background-color: rgba(0,0,0,0);
    @include transition(background-color 0.4s);

    &:hover{
      background-color: $light-beige-2;
    }
  }

  &.tertiary{
    background-color: rgba(0,0,0,0);
    border: none;
    padding-left: 0px;
    padding-right: 0px;

    h4{
      @include transition(background-color 0.4s);
    }

    &:hover h4{
      &.orange{
        background-color: $light-beige-2;
      }
      &.black{
        background-color: $orange-extra-trans;
      }
    }
  }
}

.dropdown{
  @include appearance(none);
  border: 1px solid $light-beige;
  border-radius: 0px;
  height: 50px;
  width: 100%;
  padding-left: 15px;
  font-family: 'suisse-light';
  font-size: 32px;
  line-height: 36px;
  color: $light-grey;
  background-color: $white;
  background-image: url('../../images/arrows.svg');
	background-repeat:no-repeat;
	background-position: right 0px top 0px;

}

.custom-gap-63{
  height: 63px;
}

.custom-gap-20{
  height: 20px;
}
