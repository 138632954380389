// main: App.scss

@mixin no-select(){
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin text-render-fix() {


  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

}


@mixin drop-shadow{
  box-shadow: 0px 2px 10px $black-shadow;

}

@mixin drop-shadow_2{
  box-shadow: 0px 2px 5px $black-shadow;

}

@mixin drop-shadow_3{
  box-shadow: 0px 2px 8px $black-shadow-2;

}

//transformations

@mixin translateY($val){
  transform: translateY($val);
  -moz-transform: translateY($val);
  -webkit-transform: translateY($val);
}

@mixin translateX($val){
  transform: translateX($val);
  -moz-transform: translateX($val);
  -webkit-transform: translateX($val);
}

@mixin translate($val, $val2){
  transform: translate($val, $val2);
  -moz-transform: translate($val, $val2);
  -webkit-transform: translate($val, $val2);
}

//transitions

@mixin transition($val){
  transition: $val;
  -moz-transition: $val;
  -webkit-transition: $val;
}

@mixin doubleTransition($val, $val2){
  transition: $val, $val2;
  -moz-transition: $val, $val2;
  -webkit-transition: $val, $val2;
}

@mixin tripleTransition($val, $val2, $val3){
  transition: $val, $val2, $val3;
  -moz-transition: $val, $val2, $val3;
  -webkit-transition: $val, $val2, $val3;
}

//resets

@mixin appearance($val){
  appearance: $val;
  -moz-appearance: $val;
  -webkit-appearance: $val;
}

//breakpoints for media queries

@mixin breakpoint($point){
  @if $point == 400px{
    @media (max-width: 400px){
      @content;
    }
  }
  @else if $point == 480px{
    @media (max-width: 480px){
      @content;
    }
  }
  @else if $point == 800px{
    @media (max-width: 800px){
      @content;
    }
  }
  @else if $point == 767px{
    @media (max-width: 767px){
      @content;
    }
  }
  @else if $point == 640px{
    @media (max-width: 640px){
      @content;
    }
  }
  @else if $point == 960px{
    @media (max-width: 960px){
      @content;
    }
  }@else if $point == 991px{
    @media (max-width: 991px){
      @content;
    }
  }
  @else if $point == 1080px{
    @media (max-width: 1080px){
      @content;
    }
  }
}
