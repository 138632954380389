// main: App.scss

//header-small styling
header{
  position: fixed;
  top: 0px;
  width: 100%;
  color: $black;
  background-color: $white;
  height: 48px;
  z-index: 10000;
  @include drop-shadow;
  @include transition(height 0.4s ease-out);

  &.expand{
    height: 74px;

    @include breakpoint($tab_991){
      height: 48px;
    }
  }

  .hamburger-menu{

    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,0.97);
    right: -100vw;
    top: 0px;
    opacity: 0.5;
    @include doubleTransition(right 0.4s ease-out, opacity 0.4s ease-out);


    &.show{

      opacity: 1;
      right: 0px;

    }

    li{
      display: block;
    }
  }

  .logo{
    z-index: 1;
    font-size: 20px;
    position: absolute;
    left: 15px;
    top: 7px;
    //top: 50%;
    //@include translateY(-50%);

    @include breakpoint($mob_480){
      display: none;
    }
  }

  ul{
    list-style-type: none;
    margin: 0;
    padding: 0;

    &.secondaryLinks{
      display: block;
      position: absolute;
      top: 0px;
      right: 15px;
      font-family: 'suisse-regular';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: normal;

      li.reg a{
        @include transition(background-color 0.4s);

        &:hover{
        text-decoration: none;
        color: $black;
        background-color: $orange-extra-trans;
        }
      }

      @include breakpoint($tab_991){
        display: none;
      }
    }

    &.primaryLinks{
      font-family: 'suisse-antique';
      font-size: 14px;
      position: absolute;
      left: 49%;
      bottom: 20px;
      opacity: 0;
      display: none;
      white-space: nowrap;
      z-index: 999;
      @include translateX(-50%);
      letter-spacing: 0.01em;
      @include doubleTransition(opacity 0.2s ease-out  ,bottom 0.2s ease-out );

      li a{
        text-decoration: none;
        @include transition(background-color 0.4s);

        &:hover{
          color: $black;
          background-color: $orange-extra-trans;
        }
      }

      li.active a{
        color: $black;
        background-color: $orange-trans;
      }

      &.expand{
        display: block;
        opacity: 1;
        bottom: 8px;

        @include breakpoint($tab_991){
          display: none;
          opacity: 0;
          bottom: 20px;
        }
      }

      .beige{
        font-family: 'suisse-regular';
        color: $beige;
      }

      li:last-of-type{
        margin-right: 0px;
      }


    }

    li{
      display: inline-block;
      margin-right: 24px;

      a{
        text-decoration: none;
        color: $black;

        &:hover{


        }
      }



      &.cart{
        margin-right: 0;

        .cart-icon{
          display: inline-block;
          position: relative;
          margin-right: 6px;

          span{
            position: absolute;
            right:12px;
            top: 2px;
            font-family: 'suisse-regular';
            color: $orange;
            font-size: 14px;
          }
        }

        span{
          color: $orange;
          text-decoration: none;
        }
      }

        &.cart.active span{
          @include transition(background-color 0.4s);

          &:hover{
            cursor: pointer;
          }

          &:hover span{
              background-color: $light-beige-2;
          }
        }


      }

    }
  }

  .mail-icon-desktop, .phone-icon-desktop{
    img{
      height: 30px;
      margin-top: -5px;
      margin-right: 2px;
    }
  }



.logo-small{
  display: none;
  position: absolute;
  left: 15px;
  top: 50%;
  z-index: 1001;
  @include translateY(-50%);

  @include breakpoint($mob_480){
    display: block;
  }
}

.mobile-header-icons{
  display: none;
  position: absolute;
  right: 15px;
  top: 50%;
  @include translateY(-50%);
  z-index: 1001;

  @include breakpoint($tab_991){
    display: block;
  }



  .hamburger{
    display: inline-block;
    cursor: pointer;
    margin-right: 4px;

    &.close{
      padding-top: 3px;
    }


  }

  .cart-icon-mobile{
    display: inline-block;
    position: relative;
    margin-right: 25px;

    img{
      @include breakpoint($mob_480){
        width: 80%;
      }
    }

    span{
      position: absolute;
      right:12px;
      top: 0px;
      font-family: 'suisse-regular';
      color: $orange;
      font-size: 14px;

      @include breakpoint($mob_480){
        right: 17px;
        top: -3px
      }
    }

    @include breakpoint($mob_480){
      margin-right: 8px;
    }
  }

  .phone-icon-mobile{
    display: inline-block;
    margin-right: 8px;

    img{
      @include breakpoint($mob_480){
        width: 90%;
      }
    }

    @include breakpoint($mob_480){
      margin-right: 0;

    }
  }

  .mail-icon-mobile{
    display: inline-block;
    margin-right: 16px;

    img{
      @include breakpoint($mob_480){
        width: 90%;
      }
    }

    @include breakpoint($mob_480){
      margin-right: 4px;
    }
  }

}


//slight modifications to styling for hamburger menu
.hamburger-primaryLinks{
  font-family: 'suisse-antique';
  font-size: 20px;
  letter-spacing: 0.01em;

  .ham-link{
    padding: 0.3rem;
  }

  li.beige{
    font-family: 'suisse-regular';
    color: $beige;
  }

  li a{
    text-decoration: none;
    @include transition(background-color 0.4s);

    &:hover{
      color: $black;
      background-color: $orange-extra-trans;
    }
  }

  li.active a{
    color: $black;
    background-color: $orange-trans;
  }
}


 .hamburger-primaryLinks .menu-block{
   position: absolute;
   top: 50%;
   left: 50%;
   @include translate(-50%,-50%);
   text-align: center;

   li{
     margin-right: 0;
     margin-bottom: 3px;
   }


 }
